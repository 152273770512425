import { useContext, useEffect, useMemo, useState } from 'react';
import { Checkbox, TextInput, Button, Tabs, Switch, Space, NumberInput } from '@mantine/core';
import MantineTable from '../../../../common/table/mantine';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { IconRefresh, IconTrash } from '@tabler/icons-react';

const CostConditionCreation = ({ activeTab }) => {
  return (
    <>
      <div>
        <p>Hello there </p>
      </div>
    </>
  );
};

export default CostConditionCreation;
